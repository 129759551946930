import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

// Styled Feature Card
const StyledFeatureCard = styled(Box)(({ theme }) => ({
  width: "280px",
  height: "180px",
  padding: theme.spacing(3),
  borderRadius: "15px",
  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  backgroundColor: "#fff",
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.2)",
  },
}));

// Styled Typography for Title
const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "20px",
  color: "#37474f",
  marginBottom: theme.spacing(2),
}));

// Styled Typography for Description
const DescriptionText = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: "#555",
}));

const FeaturesGrid = () => {
  return (
    <Box
      sx={{
        padding: "30px 20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          color: "#122638",
          fontSize: { md: "48px", xs: "32px" },
          fontWeight: "bold",
          marginBottom: "24px",
          textAlign: "center",
        }}
      >
        What is Teazel ?
      </Typography>
      <Typography variant="body1" sx={{ color: "#37474f", textAlign: "center", maxWidth: "800px", marginBottom: "40px" }}>
        Teazel Sales is a cutting-edge CRM system built to help businesses streamline their lead generation and management
        processes. By integrating directly with your website, it captures potential customer inquiries and automates follow-ups, ensuring no opportunity is missed.
      </Typography>

      {/* Features Cards Grid */}
      <Grid container spacing={4} sx={{ justifyContent: "center", alignItems: "center", gap: 3 }}>
        { /* Array of features for dynamic generation of cards */ }
        {[
          { title: "Website Integration", description: "Automatically capture leads directly from your website." },
          { title: "Lead Tracking", description: "Monitor lead status and manage follow-ups with ease." },
          { title: "Customizable Reports", description: "Gain actionable insights with data-driven reports." },
          { title: "User-Friendly Dashboard", description: "Get a clear overview of your sales performance." },
          { title: "Interactive Charts", description: "Visualize client request and sales conversion data." },
          { title: "Multi-User Access", description: "Collaborate efficiently with team role management." }
        ].map((feature, index) => (
          <Grid item key={index}>
            <StyledFeatureCard>
              <Title>{feature.title}</Title>
              <DescriptionText>{feature.description}</DescriptionText>
            </StyledFeatureCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FeaturesGrid;
