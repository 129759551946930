import React, { useState, useEffect } from "react";
import DashboardPage from "../../../assets/images/dashboardPage.png";
import { Box, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import FeaturesGrid from "./FeaturesGrid";
import BenefitsPage from "./BenefitsPage";
import LeadPage from "./LeadPage";
import PipelinePage from "./PipelinePage";
import ReportPage from "./ReportPage";
import BackButtonNavbar from "../../../components/BackButtonNavbar/BackButtonNavbar";
import ContactPage from "./ContactPage";
import Popup from "../../../components/common/popup/Popup";

// Styled TEazel Page Container
const StyledPageContainer = styled(Box)(({ theme }) => ({
  padding: "50px 20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "100vh",
  [theme.breakpoints.down("sm")]: {
    padding: "30px 10px", // Reduce padding on small screens
  },
}));

// Styled Main Content Container
const MainContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  maxWidth: "1200px",
  backgroundColor: "#ffffff",
  borderRadius: "15px",
  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)",
  padding: theme.spacing(5),
  gap: theme.spacing(5),

  [theme.breakpoints.down("md")]: {
    flexDirection: "column", // Stack items vertically on tablets
    padding: theme.spacing(3),
    gap: theme.spacing(3),
  },

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
    gap: theme.spacing(2),
  },
}));

// Styled Left Section
const LeftSection = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  textAlign: "left",

  [theme.breakpoints.down("md")]: {
    alignItems: "center",
    textAlign: "center",
  },
}));

// Styled Right Section
const RightSection = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",

  [theme.breakpoints.down("md")]: {
    width: "100%",
  },

  img: {
    maxWidth: "100%",
    height: "auto",
    borderRadius: "10px",
  },
}));

// Styled Title
const Title = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
  color: "#37474f",
  textAlign: "center",
  fontSize: "32px",

  [theme.breakpoints.down("md")]: {
    fontSize: "28px",
  },

  [theme.breakpoints.down("sm")]: {
    fontSize: "22px",
  },
}));

// Styled Description Text
const DescriptionText = styled(Typography)(({ theme }) => ({
  color: "#37474f",
  fontSize: "16px",
  marginBottom: theme.spacing(2),
  textAlign: "left",
}));

// Updated/Button with a Dark Color to Match Navbar
const CallToActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#183153",
  color: "#ffffff",
  fontSize: "16px",
  padding: theme.spacing(1.5, 3),

  "&:hover": {
    backgroundColor: "#455a74",
  },

  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
    padding: theme.spacing(1, 2),
  },
}));


const TeazelPage = () => {
  const [popupOpen, setPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BackButtonNavbar />
      <StyledPageContainer>
      <Typography
        variant="h3"
        sx={{
          color: "#122638",
          fontSize: { md: "48px", xs: "32px" },
          fontWeight: "bold",
          marginBottom: "24px",
          textAlign: "center",
        }}
      >
        Teazel
      </Typography>
        <MainContentContainer>
          {/* Left Section */}
          <LeftSection>
            <Typography
              sx={{
                color: "#37474f",
                textAlign: "left",
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              The Ultimate Sales Lead Generation CRM
            </Typography>
            <DescriptionText>
              Welcome to Invayas! We are excited to introduce Teazel , a
              powerful Customer Relationship Management (CRM) solution designed
              to transform your sales lead generation process. Seamlessly
              connected to your website, Teazel  ensures you capture,
              manage, and convert leads effortlessly, driving your business
              growth.
            </DescriptionText>
            <CallToActionButton variant="contained"  onClick={handleOpenPopup}>
              Book for free demo
            </CallToActionButton>
          </LeftSection>

          {/* Right Section */}
          <RightSection sx={{ border: "1px solid lightgrey" }}>
            <img src={DashboardPage} alt="Description of the " width="100%" />
          </RightSection>
        </MainContentContainer>
        <FeaturesGrid />
        <BenefitsPage />
        <LeadPage />
        <PipelinePage />
        <ReportPage />
        <ContactPage/>
        <Popup open={popupOpen} onClose={handleClosePopup} />
      </StyledPageContainer>
    </>
  );
};

export default TeazelPage;
