import React, { useState } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import PipelineScreenshot from "../../../assets/images/abt.jpg"; 
import Popup from "../../../components/common/popup/Popup";

// Styled Pipeline Page Container
const StyledPageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 2), // Responsive padding
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "85vh",
}));

// Styled Content Section
const ContentSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  textAlign: "left",
  maxWidth: "600px",
  paddingRight: theme.spacing(5),

  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
    alignItems: "center",
    paddingRight: 0,
  },
}));

// Styled Screenshot Section
const ScreenshotSection = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  maxWidth: "600px",
  overflow: "hidden",
  marginTop: theme.spacing(4),

  [theme.breakpoints.down("sm")]: {
    marginTop: theme.spacing(2),
  },
}));

// Styled Image
const Image = styled("img")(({ theme }) => ({
  width: "100%",
  height: "auto",
  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)",
  borderRadius: "15px",

  [theme.breakpoints.down("sm")]: {
    maxWidth: "90%", // Reduce image size on small screens
  },
}));

// Styled Call to Action Button
const CallToActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#8C2970",
  color: "#ffffff",
  fontSize: "16px",
  padding: theme.spacing(1.5, 3),

  "&:hover": {
    backgroundColor: "#f06292",
  },

  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
    padding: theme.spacing(1, 2),
  },
}));

const PipelinePage = () => {
  const [popupOpen, setPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  return (
    <StyledPageContainer>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        {/* Left Content Section */}
        <Grid item xs={12} md={6}>
          <ContentSection>
            <Typography
              variant="h3"
              sx={{
                color: "#37474f",
                fontWeight: "bold",
                marginBottom: "20px",
                fontSize: { md: "48px", xs: "32px" }, // Smaller size on mobile
              }}
            >
              Pipeline management for sales teams
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#37474f",
                marginBottom: "20px",
                fontSize: { xs: "14px", md: "16px" }, // Reduce text size on mobile
              }}
            >
              NetHunt keeps your deals organized, gives you control over your
              sales process, and provides insights into what works and what
              doesn't.
            </Typography>
            <CallToActionButton onClick={handleOpenPopup} variant="contained">
              Book For Free Demo
            </CallToActionButton>
          </ContentSection>
        </Grid>

        {/* Right Screenshot Section */}
        <Grid item xs={12} md={6}>
          <ScreenshotSection>
            <Image src={PipelineScreenshot} alt="Pipeline Management Screenshot" />
          </ScreenshotSection>
        </Grid>
      </Grid>
      <Popup open={popupOpen} onClose={handleClosePopup} />
    </StyledPageContainer>
  );
};

export default PipelinePage;
