import React, { useEffect } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import ProductImage1 from "../../assets/images/teazelLogos.png"; // Update with your product images
import ProductImage2 from "../../assets/images/contotoolLogo.png";
import { useNavigate } from "react-router-dom";

// Styled Product Card
const StyledProductCard = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "auto",
  padding: theme.spacing(5), // Reduced padding for mobile view
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "15px",
  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)",
  backgroundColor: "#ffffff", // Neutral background to emphasize logos
  transition: "transform 0.3s ease, box-shadow 0.3s ease",
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.25)",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "18px",
  marginBottom: theme.spacing(2),
  color: "#37474f",
  textAlign: "center",
}));

const Image = styled("img")({
  width: "100%",
  height: "auto",
  maxWidth: "180px", // Reduced max width for mobile optimization
  marginBottom: "20px",
});

const DescriptionText = styled(Typography)(({ theme }) => ({
  color: "#37474f",
  fontSize: "16px",
  textAlign: "center",
  marginBottom: theme.spacing(2),
}));

const CallToActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#8C2970", // Pink button color
  color: "#ffffff",
  "&:hover": {
    backgroundColor: "#f06292",
  },
}));

const Product = () => {

  const handleOpenPopup = () => {
    alert('Coming Soon...')
  };
  const navigate = useNavigate(); // Hook for navigation

  const handleNavigation = (path) => {
    navigate(path); // Navigate to the specified path
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when the component is mounted
  }, []);

  return (
    <Box
      sx={{
        padding: { xs: "20px", sm: "40px", md: "50px 20px" },
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Header */}
      <Typography
        variant="h3"
        sx={{
          color: "#122638",
          fontSize: { xs: "32px", sm: "40px", md: "55px" },
          fontWeight: "bold",
          marginBottom: "24px",
          ml: { sm: "40px", xs: "20px", md: "10px" },
        }}
      >
        Our Products
      </Typography>

      {/* Cards */}
      <Grid
        container
        spacing={4}
        sx={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        {/* Card 1 */}
        <Grid item xs={12} sm={6} md={4}>
          <StyledProductCard>
            <Title>Got a big team?</Title>
            <Image src={ProductImage1} alt="Teazel Logo" />
            <DescriptionText>
              We know that big team implies big expenses.
            </DescriptionText>
            <CallToActionButton
              variant="contained"
              onClick={() => handleNavigation("/teazel")}
            >
              View Product
            </CallToActionButton>
          </StyledProductCard>
        </Grid>

        {/* Card 2 */}
        <Grid item xs={12} sm={6} md={4}>
          <StyledProductCard>
            <Title>Early-stage startup?</Title>
            <Image src={ProductImage2} alt="Conto Tool Logo" />
            <DescriptionText>
              Apply for NetHunt CRM special Startup Plan.
            </DescriptionText>
            <CallToActionButton  onClick={handleOpenPopup} variant="contained">
              View Product
            </CallToActionButton>
          </StyledProductCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Product;
