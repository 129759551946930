import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Button, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import Popup from "../../../components/common/popup/Popup";


// Styled Contact Page Container
const StyledPageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "100vh",
  backgroundColor: "#f0f5f9",
}));

// Styled Content Section
const ContentSection = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  maxWidth: "800px",
  marginBottom: theme.spacing(4),
  padding: theme.spacing(3),
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
}));

// Styled Contact Info Box
const ContactInfoBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#ffffff",
  padding: theme.spacing(3),
  borderRadius: "8px",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
  textAlign: "left",
  margin: theme.spacing(2),
  width: "100%",
}));

const ContactPage = () => {
  const [popupOpen, setPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };
  return (
    <StyledPageContainer>
      {/* Hero Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "800px",
          textAlign: "center",
          marginBottom: 4,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "#37474f",
            fontWeight: "bold",
            marginBottom: "16px",
          }}
        >
          Get Started Today!
        </Typography>
        <Typography variant="body1" sx={{ color: "#37474f", maxWidth: "700px" }}>
          Ready to supercharge your sales lead generation? Contact Invayas today to learn more about Teazel and schedule a free demo. Let us show you how Teazel Sales can revolutionize your sales process.
        </Typography>
      </Box>

      {/* Image */}
      <Box
        sx={{
          height: "auto",
          width: "100%",
          maxWidth: "800px",
          marginBottom: 4,
          borderRadius: "8px",
          objectFit: "cover",
        }}
        alt="Contact Us"
      />

      {/* Contact Info Section */}
      <ContentSection>
        <Typography
          variant="h6"
          sx={{ color: "#37474f", fontWeight: "bold", marginBottom: "16px" }}
        >
          Contact Us
        </Typography>
        <ContactInfoBox>
          <Typography variant="body1" sx={{ color: "#37474f" }}>
            <strong>Email:</strong> support@invayas.com
          </Typography>
        </ContactInfoBox>
        <ContactInfoBox>
          <Typography variant="body1" sx={{ color: "#37474f" }}>
            <strong>Phone:</strong> +91 9035213654
          </Typography>
        </ContactInfoBox>
        <Typography
          variant="body1"
          sx={{
            color: "#37474f",
            marginTop: "16px",
            maxWidth: "700px",
          }}
        >
          Empower your business with Teazel —your all-in-one CRM for lead generation and sales management!
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ marginTop:2 }}
          onClick={handleOpenPopup}
        >
          Schedule Your Free Demo
        </Button>
      </ContentSection>
      <Popup open={popupOpen} onClose={handleClosePopup} />
    </StyledPageContainer>
  );
};

export default ContactPage;
