import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Button, Card, CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";
import BenefitScreenshot1 from "../../../assets/images/dealsmonitor.jpg"; 
import BenefitScreenshot2 from "../../../assets/images/batchplanner.jpg"; 
import Popup from "../../../components/common/popup/Popup";

const PageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 2),
  backgroundColor: "#e8f5e9",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(8),

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(4, 2), // Reduce padding on small screens
    gap: theme.spacing(4),
  },
}));

const HeaderSection = styled(Box)(({ theme }) => ({
  textAlign: "center",
  maxWidth: "800px",
  marginBottom: theme.spacing(6),

  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(4),
  },
}));

const BenefitsGrid = styled(Grid)(({ theme }) => ({
  width: "100%",
  maxWidth: "1600px",
  justifyContent: "center",
  gap: theme.spacing(4),

  [theme.breakpoints.down("sm")]: {
    gap: theme.spacing(2),
  },
}));

const BenefitCard = styled(Card)(({ theme }) => ({
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
  borderRadius: "15px",
  textAlign: "center",
  maxWidth: "350px",
  height: "150px",
  padding: theme.spacing(3),
  backgroundColor: "#ffffff",
  "&:hover": {
    transform: "scale(1.08)",
    transition: "transform 0.3s ease-in-out",
  },

  [theme.breakpoints.down("sm")]: {
    height: "auto", // Allow height to adjust dynamically
    maxWidth: "100%",
    padding: theme.spacing(2),
  },
}));

const ScreenshotSection = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "1300px",
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(4),
  flexWrap: "wrap",
  marginTop: theme.spacing(8),

  [theme.breakpoints.down("sm")]: {
    marginTop: theme.spacing(4),
    gap: theme.spacing(2),
  },
}));

const ScreenshotContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "row",
  gap: theme.spacing(2),

  [theme.breakpoints.down("md")]: {
    flexDirection: "column", // Stack images vertically on tablets & mobile
    alignItems: "center",
  },
}));

const ScreenshotImage = styled("img")({
  width: "100%",
  maxWidth: "600px",
  height: "auto",
  borderRadius: "15px",
  boxShadow: "0px 10px 25px rgba(0, 0, 0, 0.2)",

  "@media (max-width: 600px)": {
    maxWidth: "90%", // Reduce image size on mobile
  },
});

const CallToActionButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(2, 5),
  fontSize: "20px",
  borderRadius: "10px",
  backgroundColor: "#8C2970",
  color: "#ffffff",
  marginTop: theme.spacing(4),

  "&:hover": {
    backgroundColor: "#f06292",
  },

  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    padding: theme.spacing(1.5, 3),
  },
}));

const BenefitsPage = () => {
  const [popupOpen, setPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  return (
    <PageContainer>
      <HeaderSection>
        <Typography
          variant="h3"
          sx={{
            color: "#1b5e20",
            fontWeight: "bold",
            marginBottom: "20px",

            "@media (max-width: 600px)": {
              fontSize: "24px", // Reduce font size on mobile
            },
          }}
        >
          Elevate Your Sales with Teazel
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#424242",
            lineHeight: 1.8,

            "@media (max-width: 600px)": {
              fontSize: "14px",
              lineHeight: 1.5,
            },
          }}
        >
          Transform your sales process with innovative tools designed to boost
          efficiency and maximize conversions. Discover how we can revolutionize
          your business.
        </Typography>
        <CallToActionButton onClick={handleOpenPopup}>
          Schedule Your Free Demo
        </CallToActionButton>
      </HeaderSection>

      {/* Benefits Grid */}
      <BenefitsGrid container spacing={3}>
        {[
          { title: "Seamless Lead Capture", desc: "Capture leads directly from your website, ensuring real-time updates." },
          { title: "Efficient Lead Management", desc: "Track and nurture leads with advanced tools and automated reminders." },
          { title: "Data-Driven Strategies", desc: "Use detailed analytics to identify trends and optimize strategies." },
          { title: "Boost Sales Performance", desc: "Stay updated with real-time lead requests and conversion stats." },
        ].map((benefit, index) => (
          <Grid item key={index} xs={12} sm={6} md={3}>
            <BenefitCard>
              <Typography variant="h6" sx={{ fontWeight: "bold", color: "#1b5e20" }}>
                {benefit.title}
              </Typography>
              <Typography variant="body2" sx={{ marginTop: "12px", color: "#424242" }}>
                {benefit.desc}
              </Typography>
            </BenefitCard>
          </Grid>
        ))}
      </BenefitsGrid>

      {/* Screenshot Section */}
      <ScreenshotSection>
        <ScreenshotContainer>
          <ScreenshotImage src={BenefitScreenshot1} alt="Benefit Screenshot 1" />
          <ScreenshotImage src={BenefitScreenshot2} alt="Benefit Screenshot 2" />
        </ScreenshotContainer>
      </ScreenshotSection>

      <Popup open={popupOpen} onClose={handleClosePopup} />
    </PageContainer>
  );
};

export default BenefitsPage;