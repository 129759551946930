import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import InvayasLogo from "../../../assets/images/logomain.png";
import Popup from "../../common/popup/Popup";

//pages for the navigation
const pages = ["About Us", "Product", "Services", "Why Choose Us?", "Our Team"];

// Styled components
const StyledAppBar = styled(AppBar)(({ theme, bgcolor }) => ({
  backgroundColor: bgcolor || theme.palette.background.default,
  color: "#122638",
  boxShadow: "none",
  borderBottom: `5px solid ${theme.palette.grey[300]}`,
  padding: theme.spacing(2, 0),
  position: "sticky",
  width: "100%",
  top: 0,
  left: 0,
  zIndex: theme.zIndex.appBar,
  transition: "background-color 0.3s ease",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#8C2970",
  width: "180px",
  height: "60px",
  borderRadius: "30px",

  color: "#ffffff",
  fontWeight: theme.typography.fontWeightBold,
  fontFamily: theme.typography.fontFamily,
  fontSize: "16px",
  "&:hover": {
    backgroundColor: "#B24A8E",
  },
  marginLeft: theme.spacing(3),
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  ".MuiTabs-indicator": {
    height: "1px",
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontWeight: 600,
  fontSize: "16px",
  color: "#122638",
  marginLeft: 15,
  "&.Mui-selected": {
    color: theme.palette.secondary.main,
  },
}));

const Logo = styled("img")(({ theme }) => ({
  width: "180px",
  height: "70px",
  [theme.breakpoints.down("md")]: {
    width: "180px",
    height: "70px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "150px",
    height: "60px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "150px",
    height: "60px",
  },
}));

function Navbar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [value, setValue] = useState(0);
  const [bgColor, setBgColor] = useState("transparent");
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setBgColor(window.scrollY > 50 ? "#ffffff" : "transparent");
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const scrollToSection = (section) => {
    const sectionId = section.toLowerCase().replace(/ /g, "-");
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      console.warn(`Element with ID ${sectionId} not found.`);
    }
  };

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  return (
    <React.Fragment>
      <StyledAppBar bgcolor={bgColor}>
        <Toolbar>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              alignItems: "center",
              justifyContent: "left",
              flexGrow: 1,
            }}
          >
            <Box>
              <IconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>

            <Box>
              <Logo src={InvayasLogo} alt="logo" />
            </Box>
          </Box>

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",

              justifyContent: "space-between",
              flexGrow: 1,
            }}
          >
            <Box>
              <Logo src={InvayasLogo} alt="Invayas company logo" />
            </Box>

            <Box
              sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
            >
              <StyledTabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
              >
                {pages.map((page) => (
                  <StyledTab
                    key={page}
                    label={page}
                    onClick={() => scrollToSection(page)}
                  />
                ))}
              </StyledTabs>
              <StyledButton onClick={handleOpenPopup}>CONTACT US</StyledButton>
            </Box>
          </Box>

          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
          >
            {pages.map((page) => (
              <MenuItem
                key={page}
                onClick={() => {
                  handleCloseNavMenu();
                  scrollToSection(page);
                }}
              >
                <Typography textAlign="center">{page}</Typography>
              </MenuItem>
            ))}
            <MenuItem onClick={handleOpenPopup}>
              <Typography textAlign="center">CONTACT US</Typography>
            </MenuItem>
          </Menu>
        </Toolbar>
      </StyledAppBar>

      <Popup open={popupOpen} onClose={handleClosePopup} />
    </React.Fragment>
  );
}

export default Navbar;
