import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import BenefitScreenshot2 from "../../../assets/images/dashboardPage.png";

// Styled Lead Page Container
const StyledPageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "100vh",
  backgroundColor: "#f0f5f9",
}));

// Styled Content Section
const ContentSection = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  maxWidth: "800px",
  marginBottom: theme.spacing(4),
  padding: theme.spacing(3),
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
}));

// Styled Info Box
const InfoBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#ffffff",
  padding: theme.spacing(3),
  borderRadius: "8px",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
  textAlign: "left",
  margin: theme.spacing(2),
  height: "150px",
}));

const LeadPage = () => {
  return (
    <StyledPageContainer>
      <Typography
          variant="h4"
          sx={{
            color: "#37474f",
            fontWeight: "bold",
            marginBottom: "16px",
          }}
        >
          Teazel Dashboard Highlights
        </Typography>
      {/* Content Section */}
      <ContentSection>
        
        <Typography
          variant="body1"
          sx={{
            color: "#37474f",
            maxWidth: "600px",
            margin: "auto",
          }}
        >
          Get an overview of daily and monthly lead requests, track sales conversion data, and utilize yearly data filters to forecast trends. Navigate through essential modules like Leads, MyLeads, Reports, and User Master with ease.
        </Typography>
        <Box
          component="img"
          src={BenefitScreenshot2}
          sx={{
            height: "auto",
            width: "100%",
            maxWidth: "500px",
            borderRadius: "8px",
            objectFit: "cover",
          }}
          alt="Dashboard Example"
        />
      </ContentSection>

      {/* Information Boxes Section */}
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={5}>
          <InfoBox>
            <Typography
              variant="h6"
              sx={{ color: "#37474f", fontWeight: "bold" }}
            >
              Lead Overview
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#37474f", marginTop: "12px" }}
            >
              View daily and monthly lead requests along with follow-up statuses.
            </Typography>
          </InfoBox>
        </Grid>
        <Grid item xs={12} md={5}>
          <InfoBox>
            <Typography
              variant="h6"
              sx={{ color: "#37474f", fontWeight: "bold" }}
            >
              Sales Conversion Data
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#37474f", marginTop: "12px" }}
            >
              Monitor revenue growth with interactive charts showing monthly performance.
            </Typography>
          </InfoBox>
        </Grid>
        <Grid item xs={12} md={5}>
          <InfoBox>
            <Typography
              variant="h6"
              sx={{ color: "#37474f", fontWeight: "bold" }}
            >
              Yearly Data Filters
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#37474f", marginTop: "12px" }}
            >
              Analyze historical data trends for better sales forecasting.
            </Typography>
          </InfoBox>
        </Grid>
        <Grid item xs={12} md={5}>
          <InfoBox>
            <Typography
              variant="h6"
              sx={{ color: "#37474f", fontWeight: "bold" }}
            >
              Simple Navigation
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "#37474f", marginTop: "12px" }}
            >
              Access essential modules like Leads, MyLeads, Reports, and User Master with a clean and intuitive interface.
            </Typography>
          </InfoBox>
        </Grid>
      </Grid>
    </StyledPageContainer>
  );
};

export default LeadPage;
