import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

// Styled Report Page Container
const StyledPageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 2), // Responsive padding
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  minHeight: "85vh",
}));

// Styled Content Section
const ContentSection = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  maxWidth: "800px",
  marginBottom: theme.spacing(5),

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(0, 2), // Adjust padding on mobile
  },
}));

// Styled Info Box
const InfoBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#ffffff",
  padding: theme.spacing(3),
  borderRadius: "15px",
  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)",
  textAlign: "left",
  margin: theme.spacing(2),
  minWidth: "280px", // Prevents small box size on mobile

  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));

const ReportPage = () => {
  return (
    <StyledPageContainer>
      {/* Content Section */}
      <ContentSection>
        <Typography
          variant="h3"
          sx={{
            color: "#37474f",
            fontWeight: "bold",
            marginBottom: "20px",
            fontSize: { md: "48px", xs: "28px" }, // Smaller size on mobile
          }}
        >
          Why Teazel is Perfect for Your Business
        </Typography>
      </ContentSection>

      {/* Information Boxes Section */}
      <Grid container spacing={2} justifyContent="center">
        {[
          { title: "Seamless Integration", description: "Connects effortlessly to your website, eliminating manual data entry." },
          { title: "Improved Efficiency", description: "Automate repetitive tasks and focus on closing deals." },
          { title: "Scalable Solution", description: "Suitable for small businesses and large enterprises alike." },
          { title: "Dedicated Support", description: "Round-the-clock support to help you maximize the potential of Teazel." }
        ].map((item, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <InfoBox>
              <Typography variant="h6" sx={{ color: "#37474f", fontWeight: "bold" }}>
                {item.title}
              </Typography>
              <Typography variant="body1" sx={{ color: "#37474f", marginTop: "16px", fontSize: { xs: "14px", md: "16px" } }}>
                {item.description}
              </Typography>
            </InfoBox>
          </Grid>
        ))}
      </Grid>
    </StyledPageContainer>
  );
};

export default ReportPage;
