import React,{useEffect} from "react";
import {
  Container,
  Typography,
  Paper,
  Link,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
} from "@mui/material";
import BackButtonNavbar from "../BackButtonNavbar/BackButtonNavbar";

const PrivacyPolicy = () => {
   useEffect(() => {
      window.scrollTo(0, 0); // Scroll to top when the component is mounted
    }, []);
  return (
    <>
      <BackButtonNavbar />
      <Container component="main" maxWidth="lg" sx={{ padding: 4, mt: "10px" }}>
        <Paper elevation={3} sx={{ padding: 3 }}>
          <Typography variant="h2" component="h1" gutterBottom>
            Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph>
            Last updated: September 01, 2024
          </Typography>
          <Typography variant="body1" paragraph>
            This Privacy Policy describes Our policies and procedures on the
            collection, use, and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </Typography>
          <Typography variant="body1" paragraph>
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy. This Privacy
            Policy has been created with the help of the{" "}
            <Link
              href="https://www.termsfeed.com/privacy-policy-generator/"
              target="_blank"
              rel="noopener"
            >
              Privacy Policy Generator
            </Link>
            .
          </Typography>

          <Typography variant="h3" component="h2" gutterBottom>
            Interpretation and Definitions
          </Typography>

          <Typography variant="h3" component="h3" gutterBottom>
            Interpretation
          </Typography>
          <Typography variant="body1" paragraph>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </Typography>

          <Typography variant="h3" component="h3" gutterBottom>
            Definitions
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Account"
                secondary="means a unique account created for You to access our Service or parts of our Service."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Affiliate"
                secondary="means an entity that controls, is controlled by or is under common control with a party, where 'control' means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Company"
                secondary="(referred to as either 'the Company', 'We', 'Us' or 'Our' in this Agreement) refers to INVAYAS TECHNOLOGIES PRIVATE LIMITED, #1207/343, 9th MAIN, 7th SECTOR, HSR LAYOUT, BANGALORE, KARNATAKA."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Cookies"
                secondary="are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Country"
                secondary="refers to: Karnataka, India"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Device"
                secondary="means any device that can access the Service such as a computer, a cellphone or a digital tablet."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Personal Data"
                secondary="is any information that relates to an identified or identifiable individual."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Service"
                secondary="refers to the Website."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Service Provider"
                secondary="means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Usage Data"
                secondary="refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit)."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Website"
                secondary="refers to Invayas, accessible from https://www.invayas.com/"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="You"
                secondary="means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable."
              />
            </ListItem>
          </List>

          <Divider sx={{ my: 4 }} />

          <Typography variant="h3" component="h2" gutterBottom>
            Collecting and Using Your Personal Data
          </Typography>

          <Typography variant="h3" component="h3" gutterBottom>
            Types of Data Collected
          </Typography>

          <Typography variant="h4" component="h4" gutterBottom>
            Personal Data
          </Typography>
          <Typography variant="body1" paragraph>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Email address" />
            </ListItem>
            <ListItem>
              <ListItemText primary="First name and last name" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Phone number" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Address, State, Province, ZIP/Postal code, City" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Usage Data" />
            </ListItem>
          </List>

          <Typography variant="h4" component="h4" gutterBottom>
            Usage Data
          </Typography>
          <Typography variant="body1" paragraph>
            Usage Data is collected automatically when using the Service.
          </Typography>
          <Typography variant="body1" paragraph>
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </Typography>
          <Typography variant="body1" paragraph>
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </Typography>
          <Typography variant="body1" paragraph>
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </Typography>

          <Typography variant="h4" component="h4" gutterBottom>
            Tracking Technologies and Cookies
          </Typography>
          <Typography variant="body1" paragraph>
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Cookies or Browser Cookies"
                secondary="A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Web Beacons"
                secondary="Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity)."
              />
            </ListItem>
          </List>
          <Typography variant="body1" paragraph>
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
            remain on Your personal computer or mobile device when You go
            offline, while Session Cookies are deleted as soon as You close Your
            web browser. You can learn more about cookies on{" "}
            <Link
              href="https://www.termsfeed.com/blog/cookies/#What_Are_Cookies"
              target="_blank"
              rel="noopener"
            >
              TermsFeed website
            </Link>{" "}
            article.
          </Typography>
          <Typography variant="body1" paragraph>
            We use both Session and Persistent Cookies for the purposes set out
            below:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Necessary / Essential Cookies"
                secondary="Type: Session Cookies. Administered by: Us. Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Cookies Policy / Notice Acceptance Cookies"
                secondary="Type: Persistent Cookies. Administered by: Us. Purpose: These Cookies identify if users have accepted the use of cookies on the Website."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Functionality Cookies"
                secondary="Type: Persistent Cookies. Administered by: Us. Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website."
              />
            </ListItem>
          </List>
          <Typography variant="body1" paragraph>
            For more information about the cookies we use and your choices
            regarding cookies, please visit our Cookies Policy or the Cookies
            section of our Privacy Policy.
          </Typography>

          <Typography variant="h3" component="h3" gutterBottom>
            Use of Your Personal Data
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="To provide and maintain our Service"
                secondary="including to monitor the usage of our Service."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="To manage Your Account"
                secondary="to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="For the performance of a contract"
                secondary="the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="To contact You"
                secondary="To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="To provide You"
                secondary="with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="To manage Your requests"
                secondary="To attend and manage Your requests to Us."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="For business transfers"
                secondary="We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="For other purposes"
                secondary="We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience."
              />
            </ListItem>
          </List>

          <Typography variant="h3" component="h3" gutterBottom>
            Retention of Your Personal Data
          </Typography>
          <Typography variant="body1" paragraph>
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </Typography>
          <Typography variant="body1" paragraph>
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </Typography>

          <Typography variant="h3" component="h3" gutterBottom>
            Transfer of Your Personal Data
          </Typography>
          <Typography variant="body1" paragraph>
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </Typography>
          <Typography variant="body1" paragraph>
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </Typography>
          <Typography variant="body1" paragraph>
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </Typography>

          <Typography variant="h3" component="h3" gutterBottom>
            Delete Your Personal Data
          </Typography>
          <Typography variant="body1" paragraph>
            You have the right to delete or request that We assist in deleting
            the Personal Data that We have collected about You.
          </Typography>
          <Typography variant="body1" paragraph>
            Our Service may give You the ability to delete certain information
            about You from within the Service.
          </Typography>
          <Typography variant="body1" paragraph>
            You may update, amend, or delete Your information at any time by
            signing in to Your Account, if you have one, and visiting the
            account settings section that allows you to manage Your personal
            information. You may also contact Us to request access to, correct,
            or delete any personal information that You have provided to Us.
          </Typography>
          <Typography variant="body1" paragraph>
            Please note, however, that We may need to retain certain information
            when we have a legal obligation or lawful basis to do so.
          </Typography>

          <Typography variant="h3" component="h3" gutterBottom>
            Disclosure of Your Personal Data
          </Typography>

          <Typography variant="h4" component="h4" gutterBottom>
            Business Transactions
          </Typography>
          <Typography variant="body1" paragraph>
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </Typography>

          <Typography variant="h4" component="h4" gutterBottom>
            Law enforcement
          </Typography>
          <Typography variant="body1" paragraph>
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </Typography>

          <Typography variant="h4" component="h4" gutterBottom>
            Other legal requirements
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Comply with a legal obligation" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Protect and defend the rights or property of the Company" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Prevent or investigate possible wrongdoing in connection with the Service" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Protect the personal safety of Users of the Service or the public" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Protect against legal liability" />
            </ListItem>
          </List>

          <Typography variant="h3" component="h3" gutterBottom>
            Security of Your Personal Data
          </Typography>
          <Typography variant="body1" paragraph>
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </Typography>

          <Typography variant="h3" component="h2" gutterBottom>
            Children's Privacy
          </Typography>
          <Typography variant="body1" paragraph>
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </Typography>
          <Typography variant="body1" paragraph>
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </Typography>

          <Typography variant="h3" component="h2" gutterBottom>
            Links to Other Websites
          </Typography>
          <Typography variant="body1" paragraph>
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </Typography>
          <Typography variant="body1" paragraph>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </Typography>

          <Typography variant="h3" component="h2" gutterBottom>
            Changes to this Privacy Policy
          </Typography>
          <Typography variant="body1" paragraph>
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </Typography>
          <Typography variant="body1" paragraph>
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </Typography>
          <Typography variant="body1" paragraph>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </Typography>

          <Typography variant="h3" component="h2" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions about this Privacy Policy, You can contact
            us:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="By email: support@invayas.com" />
            </ListItem>
          </List>
        </Paper>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
