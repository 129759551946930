import "./App.css";
import Clients from "./components/layout/Clients/Clients";
import Footer from "./components/layout/Footer/Footer";
import Navbar from "./components/layout/Navbar/Navbar";
import Startup from "./components/layout/Startup/Startup";
import WorkflowSection from "./components/layout/Workflow/WorkflowSection";
import WorkTogether from "./components/layout/WorkTogether/WorkTogether";
import AboutUs from "./pages/AboutUs/AboutUs";
import Home from "./pages/Home/Home";
import OurTeam from "./pages/OurTeam/OurTeam";
import Services from "./pages/Services/Services";
import WhyChooseUs from "./pages/WhyChooseUs/WhychooseUs";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TermsAndConditions from "./components/layout/Terms/TermsAndConditions";
import Products from "./pages/Products/Product";
import TeazelPage from "./pages/Products/Teazel/TeazelPage";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <section id="home">
                  <Home />
                </section>
                <section id="work-together">
                  <WorkTogether />
                </section>
                <section id="about-us">
                  <AboutUs />
                </section>
                <section id="clients">
                  <Clients />
                </section>
                <section id="why-choose-us?">
                  <WhyChooseUs />
                </section>
                <section id="workflow">
                  <WorkflowSection />
                </section>
                <section id="product">
                  <Products />
                </section>
                <section id="services">
                  <Services />
                </section>
                <section id="our-team">
                  <OurTeam />
                </section>
                <section id="startup">
                  <Startup />
                </section>
                <section id="footer">
                  <Footer />
                </section>
              </>
            }
          />
          <Route path="/teazel" element={<TeazelPage />} />
          <Route
            path="/privacy-policy"
            element={
              <>
                <PrivacyPolicy />
              </>
            }
          />
          <Route
            path="/terms-conditions"
            element={
              <>
                <TermsAndConditions />
              </>
            }
          />
          <Route path="*" element={<div>404 - Page Not Found</div>} />{" "}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
