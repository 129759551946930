import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import DesktopImg from "../../assets/images/desktoplmg.png";

// Styled components with theme adjustments
const StyledImage = styled("img")(({ theme }) => ({
  width: "100%",
  maxWidth: "100%",
  height: "auto",
  borderRadius: "8px",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
    margin: "0 auto",
  },
}));

const StyledTitleTypography = styled(Typography)(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(2),
  fontFamily: theme.typography.fontFamily,
  fontSize: "55px",
  fontWeight: 700,
  lineHeight: "1.2",
  textAlign: "left",
  color: "#122638",
  [theme.breakpoints.down("md")]: {
    fontSize: "55px",
    lineHeight: "1.3",
  },
  [theme.breakpoints.up("xs")]: {
    lineHeight: "1.4",
  },
  [theme.breakpoints.down("sm")]: {
    lineHeight: "1.4",
  },
}));

const StyledBodyTypography = styled(Typography)(({ theme }) => ({
  width: "100%",
  marginTop: theme.spacing(3),
  fontFamily: theme.typography.fontFamily,
  fontSize: "20px",
  fontWeight: 500,
  lineHeight: "1.6",
  textAlign: "left",
  color: "#636A87",
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
    lineHeight: "1.6",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "16px",
    lineHeight: "1.5",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    lineHeight: "1.5",
  },
}));

const ContentWrapper = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(4),
  margin: "0 8vh",
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(2),
    margin: "0 3vh",
  },
  [theme.breakpoints.down("xs")]: {
    paddingTop: theme.spacing(2),
    margin: "0 3vh",
  },

}));

const Home = () => {
  return (
    <ContentWrapper>
      <Grid container spacing={2} sx={{ mt: { sm: "2vh", xs: "0.1vh",

       } }}>
        <Grid item xs={12}>
          <StyledTitleTypography
            variant="h3"
            component="h1"
            sx={{
              textAlign: { md: "left", xs: "left" },
              fontSize: { xs: "24px", md: "55px" },
              whiteSpace: { xs: "none", md: "pre-wrap" },
            }}
          >
            Complete solutions to all kinds{"\n"}
            of applications that transform{"\n"}
            businesses for the better.
          </StyledTitleTypography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        sx={{ padding: { xs: "10px", sm: "20px", md: "30px" } }}
      >
        <Grid
          item
          xs={12}
          md={6}
          container
          alignItems="center"
          justifyContent="center"
        >
          <StyledImage src={DesktopImg} alt="Desktop" loading="lazy" />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledBodyTypography
            variant="body1"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            We are software professionals who can convert your ideas into great
            products.
          </StyledBodyTypography>
          <StyledBodyTypography
            variant="body1"
            sx={{ textAlign: { md: "left", xs: "left" } }}
          >
            Despite an ever-changing world, one thing has always been clear to
            us and remained at the heart of our proposition: to get to great
            thoughts and strategies you must start with people.
          </StyledBodyTypography>
        </Grid>
      </Grid>
    </ContentWrapper>
  );
};

export default Home;
